<template>
    <div>
      <hourly-annual-leave-employee-list></hourly-annual-leave-employee-list>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  